var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "合同编号", prop: "contractNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入合同编号" },
                model: {
                  value: _vm.form.contractNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contractNo", $$v)
                  },
                  expression: "form.contractNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "签约日期", prop: "signingDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.signingDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "signingDate", $$v)
                  },
                  expression: "form.signingDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "我方单位名称", prop: "ourCompanyName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入我方单位名称" },
                model: {
                  value: _vm.form.ourCompanyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ourCompanyName", $$v)
                  },
                  expression: "form.ourCompanyName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "我方联系人及联系方式",
                prop: "ourContactsPhone",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入我方联系人及联系方式" },
                model: {
                  value: _vm.form.ourContactsPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ourContactsPhone", $$v)
                  },
                  expression: "form.ourContactsPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "对方单位名称", prop: "partnerCompanyName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入对方单位名称" },
                model: {
                  value: _vm.form.partnerCompanyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "partnerCompanyName", $$v)
                  },
                  expression: "form.partnerCompanyName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "对方负责人及联系方式",
                prop: "partnerContactsPhone",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入对方负责人及联系方式" },
                model: {
                  value: _vm.form.partnerContactsPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "partnerContactsPhone", $$v)
                  },
                  expression: "form.partnerContactsPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "合同类型", prop: "contractType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择合同类型" },
                  model: {
                    value: _vm.form.contractType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "contractType", $$v)
                    },
                    expression: "form.contractType",
                  },
                },
                _vm._l(_vm.contractTypeOptions, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.dictValue, attrs: { value: item.dictValue } },
                    [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "合同名称", prop: "contractName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入合同名称" },
                model: {
                  value: _vm.form.contractName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contractName", $$v)
                  },
                  expression: "form.contractName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "合同约定生效方式",
                prop: "contractEffectiveMode",
              },
            },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择合同约定生效方式" },
                  model: {
                    value: _vm.form.contractEffectiveMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "contractEffectiveMode", $$v)
                    },
                    expression: "form.contractEffectiveMode",
                  },
                },
                _vm._l(_vm.effectiveOptions, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.dictValue, attrs: { value: item.dictValue } },
                    [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.contractEffectiveMode == "1"
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "合同约定生效日期",
                    prop: "contractEffectiveDate",
                  },
                },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "YYYY-MM-DD HH:mm:ss",
                      format: "YYYY-MM-DD HH:mm:ss",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.contractEffectiveDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contractEffectiveDate", $$v)
                      },
                      expression: "form.contractEffectiveDate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "合同约定失效方式", prop: "contractFailureMode" },
            },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择合同约定生效方式" },
                  model: {
                    value: _vm.form.contractFailureMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "contractFailureMode", $$v)
                    },
                    expression: "form.contractFailureMode",
                  },
                },
                _vm._l(_vm.failureOptions, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.dictValue, attrs: { value: item.dictValue } },
                    [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.contractFailureMode == "1"
            ? _c(
                "a-form-model-item",
                {
                  attrs: { label: "合同失效日期", prop: "contractFailureDate" },
                },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "YYYY-MM-DD HH:mm:ss",
                      format: "YYYY-MM-DD HH:mm:ss",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.contractFailureDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contractFailureDate", $$v)
                      },
                      expression: "form.contractFailureDate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "金额类型", prop: "amountType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择金额类型" },
                  model: {
                    value: _vm.form.amountType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "amountType", $$v)
                    },
                    expression: "form.amountType",
                  },
                },
                _vm._l(_vm.amountTypeOptions, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.dictValue, attrs: { value: item.dictValue } },
                    [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.amountType == "1" || _vm.form.amountType == "2"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "合同金额(元)", prop: "contractAmount" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "input-filter",
                        rawName: "v-input-filter:number",
                        arg: "number",
                      },
                    ],
                    attrs: { placeholder: "请输入合同金额(元)" },
                    model: {
                      value: _vm.form.contractAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contractAmount", $$v)
                      },
                      expression: "form.contractAmount",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.amountType == "1" || _vm.form.amountType == "2"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "发票类型", prop: "invoiceType" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择发票类型" },
                      model: {
                        value: _vm.form.invoiceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "invoiceType", $$v)
                        },
                        expression: "form.invoiceType",
                      },
                    },
                    _vm._l(_vm.invoiceTypeOptions, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.dictValue,
                          attrs: { value: item.dictValue },
                        },
                        [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.amountType == "1" || _vm.form.amountType == "2"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "税率", prop: "taxRate" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "input-filter",
                        rawName: "v-input-filter:number",
                        arg: "number",
                      },
                    ],
                    attrs: { placeholder: "请输入税率" },
                    model: {
                      value: _vm.form.taxRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "taxRate", $$v)
                      },
                      expression: "form.taxRate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "附件", prop: "enclosureImage" } },
            [
              _c("many-file-upload", {
                attrs: {
                  "init-file-list": _vm.initFileList,
                  folder: "enclosure",
                  type: "image",
                  maxFileCount: 10,
                },
                on: {
                  onUploading: function ($event) {
                    _vm.uploading = $event
                  },
                  billImageNum: function ($event) {
                    _vm.form.enclosureImageNum = $event
                  },
                  fileList: function ($event) {
                    _vm.form.enclosureImageUrl =
                      $event && $event.length > 0
                        ? $event.map(function (p) {
                            return p.url
                          })
                        : []
                    _vm.form.enclosureImage =
                      $event && $event.length > 0
                        ? $event
                            .map(function (p) {
                              return p.fileName
                            })
                            .join(",")
                        : ""
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }