<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="合同编号" prop="contractNo">
        <!--  公司简写字母—合同类型—日期—第几个合同.例如：YWKJ—XS—20201126001 采购 CG，销售XS，人事RS ，战略ZL，融资RZ。 -->
        <a-input v-model="form.contractNo" placeholder="请输入合同编号" />
      </a-form-model-item>
      <a-form-model-item label="签约日期" prop="signingDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.signingDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="我方单位名称" prop="ourCompanyName">
        <a-input v-model="form.ourCompanyName" placeholder="请输入我方单位名称" />
      </a-form-model-item>
      <a-form-model-item label="我方联系人及联系方式" prop="ourContactsPhone">
        <a-input v-model="form.ourContactsPhone" placeholder="请输入我方联系人及联系方式" />
      </a-form-model-item>
      <a-form-model-item label="对方单位名称" prop="partnerCompanyName">
        <a-input v-model="form.partnerCompanyName" placeholder="请输入对方单位名称" />
      </a-form-model-item>
      <a-form-model-item label="对方负责人及联系方式" prop="partnerContactsPhone">
        <a-input v-model="form.partnerContactsPhone" placeholder="请输入对方负责人及联系方式" />
      </a-form-model-item>
      <a-form-model-item label="合同类型" prop="contractType">
        <a-select placeholder="请选择合同类型" v-model="form.contractType">
          <a-select-option v-for="item in contractTypeOptions" :key="item.dictValue" :value="item.dictValue">
            {{ item.dictLabel }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="合同名称" prop="contractName">
        <a-input v-model="form.contractName" placeholder="请输入合同名称" />
      </a-form-model-item>
      <a-form-model-item label="合同约定生效方式" prop="contractEffectiveMode">
        <!--  1 约定生效日期 2 盖章后生效 3 未约定生效日期 -->
        <a-select placeholder="请选择合同约定生效方式" v-model="form.contractEffectiveMode">
          <a-select-option v-for="item in effectiveOptions" :key="item.dictValue" :value="item.dictValue">
            {{ item.dictLabel }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.contractEffectiveMode == '1'" label="合同约定生效日期" prop="contractEffectiveDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.contractEffectiveDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="合同约定失效方式" prop="contractFailureMode">
        <!--  1 合同约定终止时间 2 附条件终止(违约场景除外) 3 履行完结终止 4 未约定失效日期 -->
        <!-- <a-input v-model="form.contractFailureMode" placeholder="请输入合同约定失效方式" /> -->
        <a-select placeholder="请选择合同约定生效方式" v-model="form.contractFailureMode">
          <a-select-option v-for="item in failureOptions" :key="item.dictValue" :value="item.dictValue">
            {{ item.dictLabel }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.contractFailureMode == '1'" label="合同失效日期" prop="contractFailureDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.contractFailureDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="金额类型" prop="amountType">
        <a-select placeholder="请选择金额类型" v-model="form.amountType">
          <a-select-option v-for="item in amountTypeOptions" :key="item.dictValue" :value="item.dictValue">
            {{ item.dictLabel }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.amountType == '1' || form.amountType == '2'"
        label="合同金额(元)"
        prop="contractAmount"
      >
        <a-input v-model="form.contractAmount" v-input-filter:number placeholder="请输入合同金额(元)" />
      </a-form-model-item>
      <a-form-model-item v-if="form.amountType == '1' || form.amountType == '2'" label="发票类型" prop="invoiceType">
        <a-select placeholder="请选择发票类型" v-model="form.invoiceType">
          <a-select-option v-for="item in invoiceTypeOptions" :key="item.dictValue" :value="item.dictValue">
            {{ item.dictLabel }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.amountType == '1' || form.amountType == '2'" label="税率" prop="taxRate">
        <a-input v-model="form.taxRate" v-input-filter:number placeholder="请输入税率" />
      </a-form-model-item>
      <a-form-model-item label="附件" prop="enclosureImage">
        <!-- <file-upload v-model="form.enclosureImage" type="image"></file-upload> -->
        <many-file-upload
          :init-file-list="initFileList"
          folder="enclosure"
          type="image"
          :maxFileCount="10"
          @onUploading="uploading = $event"
          @billImageNum="form.enclosureImageNum = $event"
          @fileList="
            form.enclosureImageUrl = $event && $event.length > 0 ? $event.map(p => p.url) : []
            form.enclosureImage = $event && $event.length > 0 ? $event.map(p => p.fileName).join(',') : ''
          "
        ></many-file-upload>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getApprovalContract, addApprovalContract, updateApprovalContract } from '@/api/iot/approvalContract'
import ManyFileUpload from '@/components/ManyFileUpload'

export default {
  name: 'CreateForm',
  props: {
    amountTypeOptions: {
      type: Array,
      default: () => []
    },
    invoiceTypeOptions: {
      type: Array,
      default: () => []
    },
    contractTypeOptions: {
      type: Array,
      default: () => []
    },
    effectiveOptions: {
      type: Array,
      default: () => []
    },
    failureOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ManyFileUpload
  },
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        contractNo: null,
        // approvalStatus: 0,
        signingDate: null,
        ourCompanyName: null,
        ourContactsPhone: null,
        partnerCompanyName: null,
        partnerContactsPhone: null,
        contractType: undefined,
        contractName: null,
        contractEffectiveMode: undefined,
        contractEffectiveDate: null,
        contractFailureMode: undefined,
        contractFailureDate: null,
        amountType: undefined,
        contractAmount: null,
        invoiceType: undefined,
        taxRate: null,
        enclosureImage: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        signingDate: [{ required: true, message: '签约日期不能为空', trigger: 'change' }],
        ourCompanyName: [{ required: true, message: '我方单位名称不能为空', trigger: 'blur' }],
        partnerCompanyName: [{ required: true, message: '对方单位名称不能为空', trigger: 'blur' }],
        contractName: [{ required: true, message: '合同名称不能为空', trigger: 'blur' }],
        contractEffectiveMode: [
          {
            required: true,
            message: '合同约定生效方式不能为空',
            trigger: 'blur'
          }
        ],
        contractFailureMode: [
          {
            required: true,
            message: '合同约定失效方式不能为空',
            trigger: 'blur'
          }
        ],
        amountType: [{ required: true, message: '金额类型不能为空', trigger: 'change' }]
      },
      initFileList: {}
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    open(val) {
      if (val) {
        this.$refs.form.resetFields()
      }
    },
    'form.contractAmount'(val) {
      console.log('contractAmount', val)
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        contractNo: null,
        // approvalStatus: 0,
        signingDate: null,
        ourCompanyName: null,
        ourContactsPhone: null,
        partnerCompanyName: null,
        partnerContactsPhone: null,
        contractType: undefined,
        contractName: null,
        contractEffectiveMode: undefined,
        contractEffectiveDate: null,
        contractFailureMode: undefined,
        contractFailureDate: null,
        amountType: undefined,
        contractAmount: null,
        invoiceType: undefined,
        taxRate: null,
        enclosureImage: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getApprovalContract(id).then(response => {
        this.form = response.data
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key].toString()
          }
        }
        this.initFileList = { billImage: this.form.enclosureImage, billImageUrl: this.form.enclosureImageUrl }
        console.log('this.initFileList', this.initFileList)
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      console.log('this.form', this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateApprovalContract(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addApprovalContract(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
